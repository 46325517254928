<script setup lang="ts">
import { DialogWrapper } from 'vue3-promise-dialog';

const publicConfig = useRuntimeConfig().public;
const faviconName = publicConfig.faviconName;

useFavicon(`/${faviconName}.png`, { rel: 'icon' });

window.addEventListener('message', function (event) {
    if (event.data.type === 'iframeHeightChange') {
        console.log('Received height change in form contact:', event.data.height);
    }
}, false);
</script>

<template>
    <VApp ref="appRef" class="bg-white">
        <LayoutNotification></LayoutNotification>
        <DialogWrapper />

        <VMain>
            <VContainer fluid class="d-flex justify-center">
                <slot> </slot>
            </VContainer>
        </VMain>
    </VApp>
</template>

<style lang="scss">
@import '@fontsource/open-sans/index.css';
</style>